<template>
  <el-dialog v-el-drag-dialog :close-on-click-modal="false" :visible.sync="dialogVisible" :title="$t('contract.check')"
    width="1000px" top="3vh">
    <span style="line-height: 25px;font-weight: 600;font-size: 15px;;">
      {{ $t("workOrder.elevatorInfo") }}
    </span>
    <el-card style="margin-bottom:20px;" shadow="never">
      <el-tag v-for="tag in model.elevatorList" :key="tag.id" size="medium" style="margin-right: 10px">
        <router-link v-if="tag.id" :to="'/elevator/' + tag.id" target="_blank">
          {{ tag.no }}-{{ tag.name }}
        </router-link>
      </el-tag>
    </el-card>
    <span style="line-height: 25px;font-weight: 600;font-size: 15px;">
      {{ $t("contract.contractInfo") }}
    </span>
    <div style="margin-top: 10px">
      <div class="vm-separate">
        <div>
          <span class="contract-info">{{ $t("contract.contractNo") }}：</span>
          <span>{{ model.contractNo }}</span>
        </div>
        <div>
          <span class="contract-info">{{ $t("contract.contractSource") }}：</span>
          <span v-if="$i18n.isCn">{{ model.contractSource }}</span>
          <span v-else>{{ model.contractSourceEn }}</span>
        </div>
      </div>
      <div class="vm-separate">
        <div>
          <span class="contract-info">{{ $t("contract.contractStartDate") }}：</span>
          <span>{{ model.startDate }}</span>
        </div>
        <div>
          <span class="contract-info">{{ $t("contract.contractEndDate") }}：</span>
          <span>{{ model.endDate }}</span>
        </div>
      </div>
      <div class="vm-separate">
        <div>
          <span class="contract-info">{{ $t("elevator.useUnit") }}：</span>
          <span>{{ model.useUnitName }}</span>
        </div>
        <div>
          <span class="contract-info">{{ $t("contract.siteName") }}：</span>
          <span>{{ model.siteName }}</span>
        </div>
      </div>
      <div class="vm-separate">
        <div>
          <span class="contract-info">{{ $t("contract.packagingForm") }}：</span>
          <span v-if="$i18n.isCn">{{ model.packagingForm }}</span>
          <span v-else>{{ model.packagingFormEn }}</span>
        </div>
        <div>
          <span class="contract-info">{{ $t("contract.branchAgency") }}：</span>
          <span>{{ model.branchName }}</span>
        </div>
      </div>
      <div class="vm-separate">
        <div>
          <span class="contract-info">{{ $t("contract.inspectionUndertaker") }}：</span>
          <span v-if="$i18n.isCn">{{ model.inspectionUndertaker }}</span>
          <span v-else>{{ model.inspectionUndertakerEn }}</span>
        </div>
        <div>
          <span class="contract-info">{{ $t("contract.speedLimiterUndertaker") }}：</span>
          <span v-if="$i18n.isCn">{{ model.speedLimiterUndertaker }}</span>
          <span v-else>{{ model.speedLimiterUndertakerEn }}</span>
        </div>
      </div>
      <div class="vm-separate">
        <div>
          <span class="contract-info">{{ $t("contract.brakeTestFeeUndertaker") }}：</span>
          <span v-if="$i18n.isCn">{{ model.brakeTestFeeUndertaker }}</span>
          <span v-else>{{ model.brakeTestFeeUndertakerEn }}</span>
        </div>
        <div>
          <span class="contract-info">{{ $t("contract.freeAccessoriesQuota") }}：</span>
          <span v-if="model.freeAccessoriesQuota">{{ model.freeAccessoriesQuota }}元</span>
          <span v-else></span>
        </div>
      </div>
      <div class="vm-separate">
        <div>
          <span class="contract-info">{{ $t("contract.contractPrice") }}：</span>
          <span v-if="model.contractPrice">{{ model.contractPrice }}元</span>
          <span v-else></span>
        </div>
        <div>
          <span class="contract-info">{{ $t("contract.paymentMethod") }}：</span>
          <span v-if="$i18n.isCn">{{ model.paymentMethod }}</span>
          <span v-else>{{ model.paymentMethodEn }}</span>
        </div>
      </div>
      <div class="vm-separate">
        <div>
          <span class="contract-info">{{ $t("contract.monthlyUnitPrice") }}：</span>
          <span v-if="model.monthlyUnitPrice">{{ model.monthlyUnitPrice }}元</span>
          <span v-else></span>
        </div>
        <div>
          <span class="contract-info">{{ $t("contract.agencyFees") }}：</span>
          <span v-if="model.agencyFees">{{ model.agencyFees }}元</span>
          <span v-else></span>
        </div>
      </div>
      <div class="vm-separate">
        <div>
          <span class="contract-info">{{ $t("elevator.person") }}：</span>
          <span>{{ model.contactName }}</span>
        </div>
        <div>
          <span class="contract-info">{{ $t("contract.contactInformation") }}：</span>
          <span>{{ model.contactInformation }}</span>
        </div>
      </div>
      <div class="vm-separate">
        <div>
          <span class="contract-info">{{ $t("contract.isSubcontract") }}：</span>
          <span v-if="model.isSubcontract">{{ $t("common.yes") }}</span>
          <span v-else>{{ $t("common.no") }}</span>
        </div>
        <div></div>
      </div>
    </div>
    <span slot="footer">
      <el-button @click="dialogVisible = false">{{ $t("common.cancel") }}</el-button>
    </span>
  </el-dialog>
</template>

<script>

export default {
  data() {
    return {
      dialogVisible: false,
      submitLoading: false,
      useUnitList: [],
      branchList: [],
      isCopy: false,
      model: {
        id: 0,
        branchId: 0,
        branchName: "",
        useUnitId: 0,
        useUnitName: "",
        contractNo: "",
        contractSource: "",
        contractSourceEn: "",
        startDate: "",
        endDate: "",
        siteName: "",
        packagingForm: "",
        packagingFormEn: "",
        inspectionUndertaker: "",
        inspectionUndertakerEn: "",
        speedLimiterUndertaker: "",
        speedLimiterUndertakerEn: "",
        brakeTestFeeUndertaker: "",
        brakeTestFeeUndertakerEn: "",
        freeAccessoriesQuota: "",
        monthlyUnitPrice: "",
        paymentMethod: "",
        paymentMethodEn: "",
        agencyFees: "",
        contractPrice: "",
        contactName: "",
        contactInformation: "",
        isSubcontract: 0,
        elevatorList: [],
      },
    };
  },
  methods: {
    open(id) {
      this.model.id = id;
      this.dialogVisible = true;
      if (this.model.id) {
        this.getData();
      }
    },
    getData() {
      this.$http
        .get("contract/" + this.model.id)
        .then((res) => {
          this.model = res.data;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.vm-separate {
  font-size: 14px;
  margin-bottom: 15px;
}

.contract-info {
  text-align: left;
  margin-left: 20px;
}
</style>