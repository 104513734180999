<template>
  <div>
    <vm-table ref="vmTable" v-loading="loading" :filter.sync="search" url="contract"
      @selection-change="handleSelectionChange" @row-click="handleRowClick">
      <template slot="adSearch">
        <vm-search :label="$t('common.filter')">
          <el-input v-model.trim="search.filter" :placeholder="$t('common.search')" clearable></el-input>
        </vm-search>
        <vm-search :label="$t('maintWorkOrder.branchAgency')">
          <el-select v-model="search.branchId" :placeholder="$t('common.pleaseSelect')" clearable>
            <el-option v-for="item in branchList" :key="item.id" :label="item.branchName" :value="item.id"></el-option>
          </el-select>
        </vm-search>
        <vm-search :label="$t('contract.packagingForm')">
          <el-select v-model="search.packagingForm" :placeholder="$t('common.pleaseSelect')" clearable>
            <el-option v-for="item in packagingFormList" :key="item.name" :label="$i18n.isCn ? item.name : item.desc"
              :value="item.name"></el-option>
          </el-select>
        </vm-search>
        <vm-search :label="$t('contract.contractSource')">
          <el-select v-model="search.contractSource" :placeholder="$t('common.pleaseSelect')" clearable>
            <el-option v-for="item in contractSourceList" :key="item.name" :label="$i18n.isCn ? item.name : item.desc"
              :value="item.name"></el-option>
          </el-select>
        </vm-search>
        <vm-search :label="$t('contract.expiredStatus')">
          <el-select v-model="search.isOverdue" :placeholder="$t('common.pleaseSelect')" clearable>
            <el-option v-for="item in isOverdueList" :key="item.value" :label="item.label"
              :value="item.value"></el-option>
          </el-select>
        </vm-search>
        <vm-search :label="$t('contract.contractStartDate')">
          <el-date-picker v-model="search.startTime" value-format="yyyy-MM-dd" type="date" clearable
            :placeholder="$t('yearCheck.date')">
          </el-date-picker>
        </vm-search>
        <vm-search :label="$t('contract.contractEndDate')">
          <el-date-picker v-model="search.endTime" value-format="yyyy-MM-dd" type="date" clearable
            :placeholder="$t('yearCheck.date')">
          </el-date-picker>
        </vm-search>
      </template>
      <template slot="toolbar">
        <el-button class="addBtn" @click="$refs.editPage.open(0, false)">
          {{ $t("common.add") }}
        </el-button>
        <el-button @click="copyContract()">
          {{ $t("contract.copy") }}
        </el-button>
        <el-button class="dangerBtn" @click="batchDelete()">
          {{ $t("contract.batchDelete") }}
        </el-button>
        <el-button :disabled="fileDisable" @click="handleFileUpload">
          {{ $t("contract.appendix") }}
        </el-button>
        <el-upload :action="uploadUrl" :before-upload="beforeUpload" :on-success="onUploadSuccess"
          :on-error="onUploadError" :on-exceed="onUploadExceed" :show-file-list="false" :headers="uploadHeaders"
          accept=".xlsx" :limit="1" :disabled="importLoading" style="display: inline-block;margin: 0 10px">
          <el-button :loading="importLoading">
            {{ $t("contract.importContract") }}
          </el-button>
        </el-upload>
        <el-button class="greenBtn" @click="downloadTemplate()">
          {{ $t("elevatorNetApply.downloadTemplate") }}
        </el-button>
        <el-button :loading="exportLoading" class="greenBtn" @click="exportExcel">
          {{ $t("common.export") }}
        </el-button>
      </template>
      <el-table-column type="selection" width="55" align="center">
      </el-table-column>
      <el-table-column prop="contractNo" :label="$t('contract.contractNo')" width="120px">
        <template v-slot="scope">
          <a style="cursor: pointer;" @click="$refs.contractDetails.open(scope.row.id)">
            {{ scope.row.contractNo }}
          </a>
        </template>
      </el-table-column>
      <el-table-column prop="branchName" :label="$t('maintWorkOrder.branchAgency')" width="130px"
        align="center"></el-table-column>
      <el-table-column prop="contractSource" :label="$t('contract.contractSource')" width="140px" align="center">
        <template v-slot="scope">
          <div v-if="$i18n.isCn">
            <span>{{ scope.row.contractSource }}</span>
          </div>
          <div v-else>
            <span>{{ scope.row.contractSourceEn }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="useUnitName" :label="$t('elevator.useUnit')"></el-table-column>
      <el-table-column prop="siteName" :label="$t('contract.siteName')"></el-table-column>
      <el-table-column prop="startDate" :label="$t('contract.contractStartDate')" width="160px"
        align="center"></el-table-column>
      <el-table-column prop="endDate" :label="$t('contract.contractEndDate')" width="150px"
        align="center"></el-table-column>
      <el-table-column :label="$t('contract.remindExpired')" width="150px" align="center">
        <template v-slot="scope">
          <div v-if="scope.row.isOverdue === 1">
            <el-tag type="danger">{{ $t("contract.beExpired") }}</el-tag>
          </div>
          <div v-if="scope.row.isOverdue === 2">
            <el-tag type="warning">{{ $t("contract.expiresMonth") }}</el-tag>
          </div>
          <div v-if="scope.row.isOverdue === 3">
            <el-tag>{{ $t("contract.expiresThreeMonths") }}</el-tag>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="packagingForm" :label="$t('contract.packagingForm')" width="140px" align="center">
        <template v-slot="scope">
          <div v-if="$i18n.isCn">
            {{ scope.row.packagingForm }}
          </div>
          <div v-else>
            {{ scope.row.packagingFormEn }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="contractPrice" :label="$t('contract.contractPrice')" width="180px"
        align="center"></el-table-column>
      <el-table-column :label="$t('common.operate')" align="center" width="80" fixed="right">
        <template v-slot="scope">
          <el-button type="text" @click="$refs.editPage.open(scope.row.id, false)">
            {{ $t("common.edit") }}
          </el-button>
        </template>
      </el-table-column>
    </vm-table>
    <edit-page ref="editPage" @save-success="getList(-1)"></edit-page>
    <contract-details ref="contractDetails"></contract-details>
    <contract-file ref="contractFile" @on-success="getList(-1)"></contract-file>
  </div>
</template>

<script>
import auth from "@/util/auth";
import EditPage from "./ContractEdit";
import ContractDetails from "./ContractDetails";
import ContractFile from "@/views/contract/ContractFile";

export default {
  components: { EditPage, ContractDetails, ContractFile },
  data() {
    return {
      importLoading: false,
      uploadUrl: window.config.SERVER_URL + "contract/importContract",
      uploadHeaders: { Authorization: auth.getToken() },
      loading: true,
      exportLoading: false,
      fileDisable: false,
      multiSelect: [],
      branchList: [],
      packagingFormList: [],
      contractSourceList: [],
      search: {
        filter: "",
        packagingFor: "",
        branchId: "",
        contractSource: "",
        startTime: "",
        endTime: "",
        isOverdue: "",
      },
      isOverdueList: [
        { value: 1, label: this.$t("contract.beExpired") },
        { value: 2, label: this.$t("contract.expiresMonth") },
        { value: 3, label: this.$t("contract.expiresThreeMonths") },
      ],
    };
  },
  mounted() {
    this.getList(1);
    this.getBranchList();
    this.getPackagingFormList();
    this.getContractSourceList();
  },
  methods: {
    getList(pageNum) {
      if (this.$route.params.isOverdue != null) {
        this.search.isOverdue = this.$route.params.isOverdue;
      }
      this.loading = false;
      this.$refs.vmTable.getList(pageNum);
    },
    getBranchList() {
      this.$http.get("branchAgency/list").then(res => {
        this.branchList = res.data;
      });
    },
    getPackagingFormList() {
      this.$http.get("dicts/PackagingForm").then(res => {
        this.packagingFormList = res.data;
      });
    },
    getContractSourceList() {
      this.$http.get("dicts/ContractSource").then(res => {
        this.contractSourceList = res.data;
      });
    },
    downloadTemplate() {
      if (this.$i18n.isCn) {
        window.open("/file/合同导入模板.xlsx", "_self");
      } else {
        window.open("/file/ContractTemplate.xlsx", "_self");
      }
    },
    exportExcel() {
      this.exportLoading = true;
      this.$api
        .exportFile("contract/export", {}, 60)
        .then(res => {
          this.exportLoading = false;
          let blob = new Blob([res.data]);
          let fileName = "";
          if (this.$i18n.isCn) {
            fileName = "合同信息.xlsx";
          } else {
            fileName = "Contract Info.xlsx";
          }
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            navigator.msSaveBlob(blob, fileName);
          } else {
            let a = document.createElement("a");
            a.href = URL.createObjectURL(blob);
            a.download = fileName;
            a.style.display = "none";
            document.body.appendChild(a);
            a.click();
            URL.revokeObjectURL(a.href);
            document.body.removeChild(a);
          }
        })
        .catch(() => {
          this.exportLoading = false;
        });
    },
    handleSelectionChange(val) {
      this.multiSelect = val;
      this.fileDisable = this.multiSelect.length > 1;
    },
    batchDelete() {
      if (!this.multiSelect.length) {
        this.$message.error(this.$t("contract.selectOneContract"));
        return;
      }
      let contractIds = [];
      for (let item of this.multiSelect) {
        contractIds.push(item.id);
      }
      this.$confirm(this.$t("contract.voidedContract"), this.$t("common.tips"), {
        confirmButtonText: this.$t("common.confirm"),
        cancelButtonText: this.$t("common.cancel"),
        type: "warning",
      }).then(() => {
        this.$http.post("contract/batchDelete", contractIds).then(() => {
          this.getList(1);
          this.$message.success(this.$t("maintWorkOrder.tip.invalidSuccess"));
        }).catch(() => {
          this.$message.error(this.$t("maintWorkOrder.tip.invalidError"));
        });
      });
    },
    copyContract() {
      if (!this.multiSelect.length) {
        this.$message.error(this.$t("contract.firstSelectContract"));
        return;
      }
      if (this.multiSelect.length > 1) {
        this.$message.error(this.$t("contract.mostOneContract"));
        return;
      }
      this.$refs.editPage.open(this.multiSelect[0].id, true);
    },
    handleRowClick(row, column) {
      if (column && column.label === this.$t("common.operate")) {
        return;
      }
      this.$refs.vmTable.$refs.elTable.toggleRowSelection(row);
    },
    beforeUpload(file) {
      let fileName = file.name;
      let index = fileName.lastIndexOf(".");
      let lastName = fileName.substring(index, fileName.length);
      if (lastName.toLowerCase() !== ".xlsx") {
        this.$message.error(this.$t("elevatorNetApply.uploadType"));
        return false;
      }
      this.importLoading = true;
      return true;
    },
    onUploadSuccess() {
      this.importLoading = false;
      this.$message.success(this.$t("elevatorNetApply.importSuccess"));
      this.getList(1);
    },
    onUploadError(err) {
      this.importLoading = false;
      if (!err.message) {
        this.$message.error(this.$t("elevatorNetApply.importFailure"));
      } else {
        this.$message.error(this.$t("elevatorNetApply.importFailure") + ": " + JSON.parse(err.message).message);
      }
    },
    onUploadExceed() {
      this.$message.error(this.$t("elevatorNetApply.importOnly"));
    },
    handleFileUpload() {
      if (this.multiSelect.length === 1) {
        this.$refs.contractFile.open(this.multiSelect[0]);
      } else {
        this.$message.error(this.$t("maintWorkOrder.tip.emptySingleRow") + "!");
      }
    },
  },
};
</script>

<style scoped></style>