<template>
  <el-dialog v-el-drag-dialog :close-on-click-modal="false" :visible.sync="dialogVisible" :title="EditTitle"
    width="1000px" top="3vh" @close="$reset('form')">
    <el-form ref="form" :label-width="$t('elevatorNetApply.labelWidth')" :model="model" :rules="ruleValidate">
      <el-card style="margin-bottom:20px;" shadow="never">
        <div slot="header" class="clearfix" style="font-size: 13px">
          <span>{{ $t("contract.elevatorSelect") + model.elevatorList.length + $t("contract.tower") }}</span>
          <el-button style="float: right; padding: 2px 0" type="text" @click="handleClear">
            {{ $t("maintWorkOrder.clearSelection") }}
          </el-button>
          <el-button style="float: right; padding: 2px 20px" type="text" @click="$refs.elevatorSelect.open()">
            {{ $t("elevatorUser.selectLift") }}
          </el-button>
          <elevator-select ref="elevatorSelect" :multi-selection="true" @multi-select="onMultiElevatorSelect"
            @select="onElevatorSelect"></elevator-select>
        </div>
        <el-tag v-for="tag in model.elevatorList" :key="tag.id" size="medium" style="margin-right: 10px" closable
          @close="handleTagClose(tag)">
          {{ tag.no }}-{{ tag.name }}
        </el-tag>
      </el-card>
      <div class="vm-separate">
        <el-form-item :label="$t('contract.contractNo')" prop="contractNo" :rules="$rule.notNull">
          <el-input v-model.trim="model.contractNo" :placeholder="$t('common.pleaseEnter')"></el-input>
        </el-form-item>
        <el-form-item :label="$t('contract.contractSource')" prop="contractSource" :rules="$rule.notNull">
          <el-select v-model="model.contractSource" :placeholder="$t('common.pleaseSelect')" style="width: 100%">
            <el-option v-for="item in contractSourceList" :key="item.name" :label="$i18n.isCn ? item.name : item.desc"
              :value="item.name"></el-option>
          </el-select>
        </el-form-item>
      </div>
      <div class="vm-separate">
        <el-form-item :label="$t('contract.contractStartDate')" prop="startDate" :rules="$rule.notNull">
          <el-date-picker v-model="model.startDate" type="date" :placeholder="$t('yearCheck.date')"
            value-format="yyyy-MM-dd" style="width: 100%">
          </el-date-picker>
        </el-form-item>
        <el-form-item :label="$t('contract.contractEndDate')" prop="endDate" :rules="$rule.notNull">
          <el-date-picker v-model="model.endDate" type="date" :placeholder="$t('yearCheck.date')"
            value-format="yyyy-MM-dd" style="width: 100%">
          </el-date-picker>
        </el-form-item>
      </div>
      <div class="vm-separate">
        <el-form-item :label="$t('elevator.useUnit')" prop="useUnitId">
          <el-input v-model="model.useUnitName" :placeholder="$t('common.pleaseSelect')" readonly>
            <el-button slot="append" icon="el-icon-search" @click="$refs.selectUseUnitProperty.open()"></el-button>
          </el-input>
          <use-unit-select ref="selectUseUnitProperty" @select="onSelectUseUnit"></use-unit-select>
        </el-form-item>
        <el-form-item :label="$t('contract.siteName')" prop="siteName" :rules="$rule.notNull">
          <el-input v-model.trim="model.siteName" :placeholder="$t('common.pleaseEnter')"></el-input>
        </el-form-item>
      </div>
      <div class="vm-separate">
        <el-form-item :label="$t('contract.packagingForm')" prop="packagingForm" :rules="$rule.notNull">
          <el-select v-model="model.packagingForm" :placeholder="$t('common.pleaseSelect')" style="width: 100%">
            <el-option v-for="item in packagingFormList" :key="item.name" :label="$i18n.isCn ? item.name : item.desc"
              :value="item.name"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('maintWorkOrder.branchAgency')" prop="branchId">
          <el-select v-model="model.branchId" :placeholder="$t('common.pleaseSelect')" style="width: 100%" clearable>
            <el-option v-for="item in branchList" :key="item.id" :label="item.branchName" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
      </div>
      <div class="vm-separate">
        <el-form-item :label="$t('contract.inspectionUndertaker')" prop="inspectionUndertaker" :rules="$rule.notNull">
          <el-select v-model="model.inspectionUndertaker" :placeholder="$t('common.pleaseSelect')" style="width: 100%">
            <el-option v-for="item in undertakerList" :key="item.name" :label="$i18n.isCn ? item.name : item.desc"
              :value="item.name"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('contract.speedLimiterUndertaker')" prop="speedLimiterUndertaker">
          <el-select v-model="model.speedLimiterUndertaker" :placeholder="$t('common.pleaseSelect')" style="width: 100%"
            clearable>
            <el-option v-for="item in undertakerList" :key="item.name" :label="$i18n.isCn ? item.name : item.desc"
              :value="item.name"></el-option>
          </el-select>
        </el-form-item>
      </div>
      <div class="vm-separate">
        <el-form-item :label="$t('contract.brakeTestFeeUndertaker')" prop="brakeTestFeeUndertaker"
          :rules="$rule.notNull">
          <el-select v-model="model.brakeTestFeeUndertaker" :placeholder="$t('common.pleaseSelect')"
            style="width: 100%">
            <el-option v-for="item in undertakerList" :key="item.name" :label="$i18n.isCn ? item.name : item.desc"
              :value="item.name"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('contract.freeAccessoriesQuota')" prop="freeAccessoriesQuota" :rules="$rule.float2">
          <el-input v-model.number="model.freeAccessoriesQuota" type="number"
            :placeholder="$t('common.pleaseEnter')"></el-input>
        </el-form-item>
      </div>
      <div class="vm-separate">
        <el-form-item :label="$t('contract.contractPrice')" prop="contractPrice" :rules="$rule.float2">
          <el-input v-model.number="model.contractPrice" type="number"
            :placeholder="$t('common.pleaseEnter')"></el-input>
        </el-form-item>
        <el-form-item :label="$t('contract.paymentMethod')" prop="paymentMethod">
          <el-select v-model="model.paymentMethod" :placeholder="$t('common.pleaseSelect')" style="width: 100%"
            clearable>
            <el-option v-for="item in paymentMethodList" :key="item.name" :label="$i18n.isCn ? item.name : item.desc"
              :value="item.name"></el-option>
          </el-select>
        </el-form-item>
      </div>
      <div class="vm-separate">
        <el-form-item :label="$t('contract.monthlyUnitPrice')" prop="monthlyUnitPrice" :rules="$rule.float2">
          <el-input v-model.number="model.monthlyUnitPrice" type="number"
            :placeholder="$t('common.pleaseEnter')"></el-input>
        </el-form-item>
        <el-form-item :label="$t('contract.agencyFees')" prop="agencyFees" :rules="$rule.float2">
          <el-input v-model.number="model.agencyFees" type="number" :placeholder="$t('common.pleaseEnter')"></el-input>
        </el-form-item>
      </div>
      <div class="vm-separate">
        <el-form-item :label="$t('elevator.person')" prop="contactName">
          <el-input v-model.trim="model.contactName" :placeholder="$t('common.pleaseEnter')"></el-input>
        </el-form-item>
        <el-form-item :label="$t('contract.contactInformation')" prop="contactInformation">
          <el-input v-model.trim="model.contactInformation" :placeholder="$t('common.pleaseEnter')"></el-input>
        </el-form-item>
      </div>
      <div class="vm-separate">
        <el-form-item :label="$t('contract.isSubcontract')" prop="isSubcontract">
          <el-radio v-model="model.isSubcontract" :label="1">{{ $t("common.yes") }}</el-radio>
          <el-radio v-model="model.isSubcontract" :label="0">{{ $t("common.no") }}</el-radio>
        </el-form-item>
        <div></div>
      </div>
    </el-form>
    <span slot="footer">
      <el-button @click="dialogVisible = false">{{ $t("common.cancel") }}</el-button>
      <el-button :loading="submitLoading" type="primary" @click="handleSubmit">{{ $t("common.save") }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import ElevatorSelect from "@/views/elevator/ElevatorSelect";
import UseUnitSelect from "../company/useUnitCom/UseUnitSelect.vue";

export default {
  components: { ElevatorSelect, UseUnitSelect },
  data() {
    return {
      dialogVisible: false,
      submitLoading: false,
      useUnitList: [],
      branchList: [],
      isCopy: false,
      EditTitle: "",
      model: {
        id: 0,
        branchId: "",
        branchName: "",
        useUnitId: 0,
        useUnitName: "",
        contractNo: "",
        contractSource: "",
        startDate: "",
        endDate: "",
        siteName: "",
        packagingForm: "",
        inspectionUndertaker: "",
        speedLimiterUndertaker: "",
        brakeTestFeeUndertaker: "",
        freeAccessoriesQuota: "",
        monthlyUnitPrice: "",
        paymentMethod: "",
        agencyFees: "",
        contractPrice: "",
        contactName: "",
        contactInformation: "",
        isSubcontract: 0,
        elevatorList: [],
      },
      packagingFormList: [],
      undertakerList: [],
      paymentMethodList: [],
      contractSourceList: [],
      ruleValidate: {
        useUnitId: [{
          type: "number",
          required: true,
          min: 1,
          message: this.$t("common.tip.notNull"),
          trigger: "change",
        }],
      },
    };
  },
  methods: {
    open(id, isCopy) {
      this.model.id = id;
      this.isCopy = isCopy;
      this.dialogVisible = true;
      this.getBranchList();
      this.getPackagingFormList();
      this.getUndertakerList();
      this.getPaymentMethodList();
      this.getContractSourceList();
      if (this.model.id) {
        this.getData();
      }
      if (this.model.id && !this.isCopy) {
        this.EditTitle = this.$t("common.edit");
      } else if (this.model.id && this.isCopy) {
        this.EditTitle = this.$t("contract.copy");
      } else {
        this.EditTitle = this.$t("common.add");
      }
    },
    getData() {
      this.$http
        .get("contract/" + this.model.id)
        .then((res) => {
          this.model = res.data;
        });
    },
    handleSubmit() {
      this.$refs.form.validate(valid => {
        if (!this.model.elevatorList.length) {
          this.$message.error(this.$t("yearCheck.tip"));
          return;
        }
        if (this.model.startDate > this.model.endDate) {
          this.$message.error(this.$t("workOrder.choiceDate"));
          return;
        }
        if (valid) {
          this.submitLoading = true;
          if (this.isCopy) {
            this.model.id = 0;
          }
          this.$http.save("contract", this.model)
            .then(() => {
              this.submitLoading = false;
              this.dialogVisible = false;
              this.$emit("save-success");
              this.$message.success(this.$t("common.tip.saveSuccess"));
            })
            .catch(() => {
              this.submitLoading = false;
            });
        } else {
          this.$message.error(this.$t("common.tip.submitError") + "!");
        }
      });
    },
    getBranchList() {
      this.$http.get("branchAgency/list").then(res => {
        this.branchList = res.data;
      });
    },
    getContractSourceList() {
      this.$http.get("dicts/ContractSource").then(res => {
        this.contractSourceList = res.data;
      });
    },
    getUndertakerList() {
      this.$http.get("dicts/Undertaker").then(res => {
        this.undertakerList = res.data;
      });
    },
    getPackagingFormList() {
      this.$http.get("dicts/PackagingForm").then(res => {
        this.packagingFormList = res.data;
      });
    },
    getPaymentMethodList() {
      this.$http.get("dicts/PaymentMethod").then(res => {
        this.paymentMethodList = res.data;
      });
    },
    handleTagClose(tag) {
      this.model.elevatorList.splice(this.model.elevatorList.indexOf(tag), 1);
    },
    handleClear() {
      this.model.elevatorList = [];
    },
    onMultiElevatorSelect(rows) {
      for (let row of rows) {
        let flag = true;
        for (let item of this.model.elevatorList) {
          if (item.id === row.id) {
            flag = false;
          }
        }
        if (flag) {
          this.model.elevatorList.push(row);
        }
      }
    },
    onElevatorSelect(row) {
      for (let item of this.model.elevatorList) {
        if (item.id === row.id) {
          return;
        }
      }
      this.model.elevatorList.push(row);
    },
    onSelectUseUnit(row) {
      this.model.useUnitId = row.id;
      this.model.useUnitName = row.name;
    },
  },
};
</script>

<style scoped></style>
